import React, {useState} from "react"
import {withRouter} from 'react-router-dom'
import {FormGroup, Row, Col, FormLabel, Modal, Tooltip, Overlay} from "react-bootstrap"
import isEmpty from 'lodash/isEmpty'
import Select from 'react-select'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import classnames from 'classnames'

import validation from "../utils/validation"
import Spinner from "./Spinner"
import {checkUser, loadUser} from "../actions/UserActions"
import order from "../actions/OrderActions"
import formatPrice from "../utils/formatPrice"
import IntlMessage from "./IntlMessage"
import {useIntl} from "react-intl"

function UserModal(props) {
    const [login, setLogin] = useState(false)
    const [password, setPassword] = useState(null)
    const [message, setMessage] = useState('')
    const [loading, setLoading] = useState(false)
    const intl = useIntl()

    function onSend(e, password) {
        e.preventDefault()
        if(password){
            setLoading(true)
            const data = {
                password,
                email: props.email
            }
            loadUser(data).then(res => {
                if (res === false) {
                    setMessage('message_login_unexpected')
                } else if (res === 'unauthorized') {
                    setMessage('message_login_unauthorized')
                } else {
                    props.onLoad(res)
                    close()
                }
                setLoading(false)
            })
        }else{
            setPassword('')
        }
    }

    function close() {
        props.onClose()
        setLogin(false)
        setPassword(null)
        setMessage('')
    }

    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
        >
            <Modal.Body>
                <p><IntlMessage id="text_registration_question"/></p>
                {
                    login && <form onSubmit={(e) => onSend(e, password)}>
                        <FormGroup>
                            <FormLabel htmlFor="password"><IntlMessage id="label_password"/> <span>*</span></FormLabel>
                            <input
                                type="password"
                                name="password"
                                id="password"
                                className="form-control"
                                placeholder={intl.formatMessage({id: 'placeholder_password'})}
                                value={password || ''}
                                autoFocus
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            {password === '' && <small><IntlMessage id="message_fill_password"/></small>}
                        </FormGroup>
                        {message && <p className="error-message"><IntlMessage id={message}/></p>}
                    </form>
                }
            </Modal.Body>
            <Modal.Footer>
                {
                    login ? (
                        <div className="buttons-login">
                            <button className="btn btn-theme"
                                    onClick={(e) => onSend(e, password)}
                            >
                                <IntlMessage id="button_login"/>
                            </button>
                            <button className="btn btn-theme inverse" onClick={close}><IntlMessage id="button_cancel"/></button>
                        </div>
                    ) : (
                        <div className="buttons-modal">
                            <button className="btn btn-theme"
                                    onClick={loading ? () => void (0) : () => setLogin(true)}><IntlMessage id="button_yes"/>
                            </button>
                            <button className="btn btn-theme secondary" onClick={close}><IntlMessage id="button_no"/></button>
                        </div>
                    )
                }
            </Modal.Footer>
        </Modal>
    );
}

function ProductModal(props) {

    const intl = useIntl()

    function onSelect(value) {
        props.onSelect(value)
        close()
    }

    function close() {
        props.onClose()
    }

    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
        >
            <Modal.Body>
                <p><IntlMessage id="message_select_product"/></p>
                <form>
                    <FormGroup>
                        <FormLabel htmlFor="product_modal"><IntlMessage id="label_product"/> <span></span></FormLabel>
                        <Select
                            placeholder={intl.formatMessage({id: 'placeholder_product'})}
                            value={null}
                            onChange={(e) => onSelect(e.value)}
                            options={props.products}
                            className="react-select-container"
                            classNamePrefix="react-select"
                            isMulti={false}
                            id="product_modal"
                        />
                    </FormGroup>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <div className="buttons-modal">
                    <button className="btn btn-theme secondary" onClick={close}><IntlMessage id="button_close"/></button>
                </div>
            </Modal.Footer>
        </Modal>
    );
}

const defaultForm = {
    email: '',
    identification_number: '',
    company_name: '',
    name: '',
    phone: '',
    product: '',
    materials: {},
    note: '',
    verified: false
}

const Form = (props) => {
    const [modalOpen, setOpenModal] = useState(false)
    const [modalOpen2, setOpenModal2] = useState(false)
    const [isLoading, setloading] = useState(false)
    const [disabled, setDisabled] = useState(false)
    const [isTouched, setTouched] = useState(false)
    const [form, setform] = useState({...defaultForm})
    const [formErrors, setFormErrors] = useState({})
    const intl = useIntl()
    const [materialCntCorrection, setMaterialCntCorrection] = useState(0)
    const [materialsRef, setMaterialsRef] = useState({})

    function onChangeForm(name, value, max) {
        if (!isTouched) {
            setTouched(true)
        }

        if (name === 'note' && value.length >= max) {
            return
        } else {
            form[name] = value
        }

        const f = name === 'product' ? fillMaterials(form) : form

        setform({...f})

        const e = validation(form, name)

        if (name === 'email' && !e.email && !disabled) {
            checkUser(value).then(res => {
                if (res) {
                    setOpenModal(true)
                }
            })
        }

        formErrors[name] = undefined
        if (name === 'product') {
            formErrors.materials = undefined
        }
        setFormErrors({...formErrors, ...e})
    }

    function onSubmit(e) {
        e.preventDefault()
        setloading(true)
        const vendor = new URLSearchParams(props.location.search || '').get('vendor')
        const data = {...form, slug: props.match.params.slug, vendor}
        order(data).then(res => {
            if (res) {
                props.setSuccess(true)
            }
            setloading(false)
        })
    }

    function fillMaterials(data) {
        if (data.product === '') {
            data.materials = {}
            setMaterialsRef({})
        }else if(data.product){
            const refs = {...materialsRef}
            const m = {}
            if(props.materials[data.product]){
                props.materials[data.product].map(item => {
                    if(item.required){
                        m[item.id] = item.cnt
                    }
                    refs[item.id] = {current: null}
                })
            }
            setMaterialsRef(refs)
            data.materials = m
        }
        //uncomment for default fill of all available materials
        /*
        else {
            const m = {}
            if (props.materials[data.product]) {
                props.materials[data.product].map(item => {
                    m[item.id] = item.cnt
                })
            }
            data.materials = m
        }*/
        return data
    }

    function onLoad(userData) {
        setDisabled(true)
        const data = {...userData, verified: true}

        const commonProducts = []
        props.products.filter(p => {
            if(data.products.includes(p.value)){
                commonProducts.push(p.value)
            }
        })

        if(commonProducts.length === 1){
            data.product = commonProducts[0]
        }

        delete data['products']
        const d = fillMaterials(data)
        setform({...form, ...d})
    }

    function validate(e) {
        e.preventDefault()
        const err = validation(form)
        setFormErrors({...err})
    }

    function toggleMaterial(item) {
        if (form.materials[item.id]) {
            delete form.materials[item.id]
        } else {
            form.materials[item.id] = item.cnt
            formErrors.materials = undefined
        }
        setform({...form})
    }

    function showCntCorrection(id){
        setMaterialCntCorrection(id)
        setTimeout(() => {
            setMaterialCntCorrection(0)
        }, 2000)
    }

    function onChangeCnt(item, value) {
        let v = value ? parseInt(value) : 0
        if (v < 1) {
            v = 1
        }else if (v > item.max_cnt) {
            v = item.max_cnt
            showCntCorrection(item.id)
        }
        form.materials[item.id] = v
        setform({...form})
    }

    function getTotal() {
        let [total, total2] = [0, 0]
        if (form.product !== '' && props.materials[form.product]) {
            props.materials[form.product].map(item => {
                total += (form.materials[item.id] || 0) * item.price
                total2 += (form.materials[item.id] || 0) * item.support_price
            })
        }

        return {
            total,
            total2
        }
    }

    const {total, total2} = getTotal()

    function constructMaterials() {
        if (!form.product) return []
        const materials = props.materials[form.product] || []
        return materials.map((item, index) => {
            return (
                <Row key={index} className={classnames("material-item", form.materials[item.id] ? "" : "disabled")}>
                    <Col xs={6} lg={4}>
                        <div className={`checkbox abc-checkbox abc-checkbox-primary ${item.required ? 'cursor-disabled' : ''}`}>
                            <input
                                type="checkbox"
                                name={"ch_" + form.product + '_' + item.id}
                                id={"ch_" + form.product + '_' + item.id}
                                checked={form.materials[item.id] ? true : false}
                                onChange={item.required ? null : () => toggleMaterial(item)}
                            />
                            <label htmlFor={"ch_" + form.product + '_' + item.id}>{item.name}{item.required ? '*' : ''}</label>
                        </div>
                        {item.description && <div className="small">{item.description}</div>}
                    </Col>
                    <Col xs={6} lg={2}>
                        <div className="count-picker">
                            <button
                                type="button"
                                className={classnames("btn", form.materials[item.id] === 1 && "disabled")}
                                onClick={form.materials[item.id] ? () => onChangeCnt(item, form.materials[item.id] - 1) : null}>
                                <FontAwesomeIcon icon="minus"/>
                            </button>
                            <input
                                ref={materialsRef[item.id]}
                                type="number"
                                className="form-control"
                                value={form.materials[item.id] || item.cnt || 0}
                                onChange={(e) => onChangeCnt(item, e.target.value)}/>
                            <Overlay
                                placement="top"
                                target={materialsRef[item.id].current}
                                show={materialCntCorrection === item.id}>
                                {
                                    (props) => (
                                        <Tooltip id={item.id} {...props}><IntlMessage id="max_number_replaced"/></Tooltip>
                                    )
                                }
                            </Overlay>
                            <button
                                type="button"
                                className={classnames("btn", form.materials[item.id] === item.max_cnt && "disabled")}
                                onClick={form.materials[item.id] ? () => onChangeCnt(item, form.materials[item.id] + 1) : null}>
                                <FontAwesomeIcon icon="plus"/>
                            </button>
                        </div>
                    </Col>
                    <Col lg={3} className="text-right">
                        <div>{formatPrice(((form.materials[item.id] || item.cnt) * item.price), props.localeSettings)}</div>
                    </Col>
                    <Col lg={3} className="text-right">
                        <div>{formatPrice(((form.materials[item.id] || item.cnt) * item.support_price), props.localeSettings)}</div>
                    </Col>
                </Row>
            )
        })
    }

    function constructDefaultMaterials() {
        const materials = props.defaultMaterials
        return materials.map((item, index) => {
            return (
                <Row key={index} className="material-item disabled" onClick={() => setOpenModal2(true)}>
                    <Col xs={8} lg={4}>
                        <div className="checkbox abc-checkbox abc-checkbox-primary">
                            <input
                                type="checkbox"
                                name={"ch_" + item.name}
                                id={"ch_" + item.name}
                                checked={false}
                                readOnly
                            />
                            <label htmlFor={"ch_" + item.name}>{item.name}</label>
                        </div>
                        {item.description && <div className="small">{item.description}</div>}
                    </Col>
                    <Col xs={4} lg={2}>
                        <div className="count-picker">
                            <button
                                type="button"
                                className="btn disabled">
                                <FontAwesomeIcon icon="minus"/>
                            </button>
                            <input
                                type="number"
                                className="form-control"
                                value={item.cnt}
                                readOnly
                            />
                            <button
                                type="button"
                                className="btn disabled">
                                <FontAwesomeIcon icon="plus"/>
                            </button>
                        </div>
                    </Col>
                    <Col lg={3} className="text-right">
                        <div>{formatPrice((item.cnt * item.price), props.localeSettings)}</div>
                    </Col>
                    <Col lg={3} className="text-right">
                        <div>{formatPrice((item.cnt * item.support_price), props.localeSettings)}</div>
                    </Col>
                </Row>
            )
        })
    }

    //const canSubmit = isTouched && (isEmpty(formErrors) || Object.values(formErrors).every(i => i === undefined))
    const canSubmit = isTouched && isEmpty(validation(form))

    const hasRequired = form.product && typeof props.materials[form.product] !== "undefined" ? props.materials[form.product].find(m => m.required) : false

    return (
        <div>
            <Spinner visible={isLoading}/>
            <form
                onSubmit={isLoading ? (e) => e.preventDefault() : (canSubmit ? (e) => onSubmit(e) : (e) => validate(e))}>
                <FormGroup as={Row} className={formErrors.email ? "has-error" : ""}>
                    <FormLabel column md={2} htmlFor="email"><IntlMessage id="label_email"/> <span>*</span></FormLabel>
                    <Col md={10}>
                        <input
                            type="email"
                            name="email"
                            id="email"
                            className="form-control"
                            placeholder={intl.formatMessage({id: 'placeholder_email'})}
                            value={form.email}
                            disabled={disabled}
                            onChange={disabled ? () => void (0) : (e) => onChangeForm('email', e.target.value)}
                        />
                        {formErrors.email && <small><IntlMessage id={formErrors.email}/></small>}
                    </Col>
                </FormGroup>
                <FormGroup as={Row} className={formErrors.identification_number ? "has-error" : ""}>
                    <FormLabel column md={2} htmlFor="identification_number"><IntlMessage id="label_identification_number"/> <span>*</span></FormLabel>
                    <Col md={10}>
                        <input
                            type="number"
                            name="identification_number"
                            id="identification_number"
                            className="form-control"
                            placeholder={intl.formatMessage({id: 'placeholder_identification_number'})}
                            value={form.identification_number}
                            disabled={disabled}
                            onChange={disabled ? () => void (0) : (e) => onChangeForm('identification_number', e.target.value)}
                        />
                        {formErrors.identification_number && <small><IntlMessage id={formErrors.identification_number}/></small>}
                    </Col>
                </FormGroup>
                <FormGroup as={Row} className={formErrors.company_name ? "has-error" : ""}>
                    <FormLabel column md={2} htmlFor="company_name"><IntlMessage id="label_company_name"/> <span>*</span></FormLabel>
                    <Col md={10}>
                        <input
                            type="text"
                            name="company_name"
                            id="company_name"
                            className="form-control"
                            placeholder={intl.formatMessage({id: 'placeholder_company_name'})}
                            value={form.company_name}
                            disabled={disabled}
                            onChange={disabled ? () => void (0) : (e) => onChangeForm('company_name', e.target.value)}
                        />
                        {formErrors.company_name && <small><IntlMessage id={formErrors.company_name}/></small>}
                    </Col>
                </FormGroup>
                <FormGroup as={Row}>
                    <FormLabel column md={2} htmlFor="name"><IntlMessage id="label_customer"/> </FormLabel>
                    <Col md={10}>
                        <input
                            type="text"
                            name="name"
                            id="name"
                            className="form-control"
                            placeholder={intl.formatMessage({id: 'placeholder_customer'})}
                            value={form.name}
                            onChange={(e) => onChangeForm('name', e.target.value)}
                        />
                        {formErrors.name && <small><IntlMessage id={formErrors.name}/></small>}
                    </Col>
                </FormGroup>
                <FormGroup as={Row} className={formErrors.phone ? "has-error" : ""}>
                    <FormLabel column md={2} htmlFor="phone"><IntlMessage id="label_phone"/> <span>*</span></FormLabel>
                    <Col md={10}>
                        <input
                            type="text"
                            name="phone"
                            id="phone"
                            className="form-control"
                            placeholder={intl.formatMessage({id: 'placeholder_phone'})}
                            value={form.phone}
                            onChange={(e) => onChangeForm('phone', e.target.value)}
                        />
                        {formErrors.phone && <small><IntlMessage id={formErrors.phone}/></small>}
                    </Col>
                </FormGroup>
                <FormGroup as={Row} className={formErrors.product ? "has-error" : ""}>
                    <FormLabel column md={2} htmlFor="product"><IntlMessage id="label_product"/> <span>*</span></FormLabel>
                    <Col md={10}>
                        <Select
                            placeholder={intl.formatMessage({id: 'placeholder_product'})}
                            value={props.products.filter(i => i.value === form.product)}
                            onChange={(e) => onChangeForm('product', e.value)}
                            options={props.products}
                            className="react-select-container"
                            classNamePrefix="react-select"
                            isMulti={false}
                            id="product"
                        />
                        {formErrors.product && <small><IntlMessage id={formErrors.product}/></small>}
                    </Col>
                </FormGroup>
                <div className="materials">
                    <Row>
                        <Col lg={4}></Col>
                        <Col xs={12} lg={2} className="text-center text-right-md-down">
                            <div><IntlMessage id="label_count"/></div>
                        </Col>
                        <Col lg={3} className="text-right">
                            <div><IntlMessage id="label_one_time"/></div>
                            <div className="md-hidden"><small><IntlMessage id="label_price_without_vat"/></small></div>
                        </Col>
                        <Col lg={3} className="text-right">
                            <div><IntlMessage id="label_month_support"/></div>
                            <div><small><IntlMessage id="label_price_without_vat"/></small></div>
                        </Col>
                    </Row>
                    {
                        form.product ? constructMaterials() : constructDefaultMaterials()
                    }
                    <Row>
                        <Col xs={6}>
                            <div><IntlMessage id="label_total"/></div>
                        </Col>
                        <Col xs={6} lg={3} className="text-right">
                            <div>{formatPrice(total, props.localeSettings)}</div>
                        </Col>
                        <Col lg={3} className="text-right">
                            <div>{formatPrice(total2, props.localeSettings)}</div>
                        </Col>
                        {formErrors.materials && <small className="message"><IntlMessage id={formErrors.materials}/></small>}
                    </Row>
                    {
                        hasRequired && (
                            <Row className="pt-0">
                                <Col><small><strong>*</strong> <IntlMessage id="material_required"/></small></Col>
                            </Row>
                        )
                    }
                </div>
                <FormGroup as={Row}>
                    <FormLabel column md={2} htmlFor="note"><IntlMessage id="label_note"/></FormLabel>
                    <Col md={10}>
                                <textarea
                                    name="note"
                                    id="note"
                                    className="form-control"
                                    placeholder={intl.formatMessage({id: 'placeholder_note'})}
                                    value={form.note}
                                    max={250}
                                    onChange={(e) => onChangeForm('note', e.target.value, 250)}
                                ></textarea>
                        {formErrors.note && <small><IntlMessage id={formErrors.note}/></small>}
                    </Col>
                </FormGroup>
                <button
                    className={classnames("btn btn-theme", !isLoading && canSubmit ? "" : "disabled")}
                    type="submit">
                    {
                        isLoading ? <IntlMessage id="button_sending"/> : <IntlMessage id="button_order"/>
                    }
                </button>
            </form>
            <UserModal show={modalOpen} onLoad={onLoad} email={form.email} onClose={() => setOpenModal(false)}/>
            <ProductModal show={modalOpen2} onSelect={(value) => onChangeForm('product', value)}
                          onClose={() => setOpenModal2(false)}
                          products={props.products}
            />
        </div>
    )
}

export default withRouter(Form)