import React, {useState} from "react"
import Spinner from "./Spinner"
import Message from "./Message"
import Form from "./Form"
import IntlMessage from "./IntlMessage"

export default ({data, loading}) => {
    const [success, setSuccess] = useState(false)
    return (
        <main>
                <Spinner visible={loading}/>
                {
                    !loading && (
                        data.expired ?
                            <Message h1={<IntlMessage id="heading_not_found"/>} h2={<IntlMessage id="message_not_available"/>}/>
                            : (
                                success ? <div className="main-mesage"><IntlMessage id="message_order_successfull"/></div>
                                    :
                                    <>
                                        {
                                            data.text_before &&
                                            <section>
                                                <div dangerouslySetInnerHTML={{__html: data.text_before}}/>
                                            </section>
                                        }
                                        <section>
                                            <h3><IntlMessage id="text_required_fields"/></h3>
                                            <Form products={data.products || []}
                                                  materials={data.materials || {}}
                                                  defaultMaterials={data.defaultMaterials || []}
                                                  localeSettings={data.localeSettings || {}}
                                                  setSuccess={setSuccess}
                                            />
                                        </section>
                                        {
                                            data.text_after &&
                                            <section>
                                                <div dangerouslySetInnerHTML={{__html: data.text_after}}/>
                                            </section>
                                        }
                                    </>
                            )
                    )
                }
        </main>
    )
}